<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <!--div class="box-ui-select searchbar-content">
            <div class="title"><span>{{ $t('table.head.betAmt') }}</span> :</div>
         </div-->
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.min') }} (MIN 1,000)</span></div>
           <input type="text" id="minBetAmt" :value="numberWithCommas(totalControl.minBetAmt)" @input="onlyNumber" @focusout="amtTotalControl" maxlength="11" />
         </div>~
         <div class="box-ui-select searchbar-content">
            <div class="title"><span>{{ $t('table.head.max') }} (MAX 500,000,000)</span></div>
            <input type="text" id="maxBetAmt" :value="numberWithCommas(totalControl.maxBetAmt)" @input="onlyNumber"  @focusout="amtTotalControl" maxlength="11" />
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.gameUsingYN') }}</span></div>
           <ui-toggle :textType="'default'" :checked="totalControl.compBetYn" @setYn="setTotalCompUseYn(totalControl)" />
         </div>
         <div class="box-ui-select searchbar-content">
           <button class="btn-search" type="button" @click="saveGameInfo">{{ $t('button.save') }}</button>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <thead>
             <tr>
              <th rowspan="2" width="15%">{{ $t('table.head.gameType') }}</th>
              <th colspan="2" width="20%" class="h25">{{ $t('table.head.bettingLimit') }}</th>
              <th v-if="isPT" rowspan="2" width="10%" >
                 {{ $t('table.head.rebateRate') }}
                 <span class="noti fx-center-all">({{ $t('table.head.buyAmt') }}: {{baseCreditRate}}%)</span>
              </th>
              <th rowspan="2" width="10%">{{ $t('common.rateCurrent') }}</th>
              <th rowspan="2" width="10%">{{ $t('table.head.vender') }}</th>
              <th rowspan="2" width="10%">{{ $t('table.head.loserollYN') }}</th>
              <th rowspan="2" width="10%">{{ $t('table.head.gameUsingYN') }}</th>
            </tr>
            <tr>
              <th class="h25">{{ $t('table.head.min') }}<span class="noti">(MIN <em class="roboto">1,000</em>)</span></th>
              <th class="h25">{{ $t('table.head.max') }}<span class="noti">(MAX <em class="roboto">500,000,000</em>)</span></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in settingList" :key="item.vendorCode">
              <tr>
                <td>
                  <span>{{ item.vendorName }}</span>
                </td>
                <td>
                  <input class="amount minBetAmt roboto" type="text" :value="numberWithCommas(item.minBetAmt)" @input="setItem(item,'minBetAmt',$event)" maxlength="11" />
                </td>
                <td>
                  <input class="amount maxBetAmt roboto" type="text" :value="numberWithCommas(item.maxBetAmt)" @input="setItem(item,'maxBetAmt',$event)" maxlength="11" />
                </td>
                <td v-if="isPT">
                  <span class="roboto">{{item.diffCreditRate.toFixed(2)}}%</span>
                </td>
                <td>
                  <span class="roboto">{{item.vendorCreditRate}}%</span>
                </td>
                <td>{{getVendorName(item.vendor)}}</td>
                <td>
                  <ui-toggle :textType="'default'" :checked="item.rollingRateOption === '1'" @setYn="setRollingRateOptionYn(item)"/>
                </td>
                <td>
                  <ui-toggle :textType="'default'" :checked="item.compBetYn" @setYn="setGameUseYn(item)" />
                </td>

              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import { gameSettingList, gameSettingSave } from '@/api/bet.js'
import { GAME_INFO_LIST } from '@/libs/constants'
import uiToggle from '@/components/ui/UiToggle'
import { mapState } from 'vuex'
import Memo from '@/components/common/memo'

export default {
  name: 'CasinoSetting',
  components: {
    uiToggle,
    Memo
  },
  data: function () {
    return {
      settingList: [],
      defaultGame: [],
      siteInfo: {},
      siteData: {},
      totalControl: {
        minBetAmt: 0,
        maxBetAmt: 0,
        topInsureRate: 0,
        insureUseYn: false,
        compBetYn: false
      },
      baseCreditRate: '',
      boolItems: ['insureUseYn', 'compBetYn']
    }
  },
  watch: {
    // 'totalControl.insureUseYn'(value){
    //   for(const item of this.settingList){
    //     item.insureUseYn = value;
    //   }
    // },
    // 'totalControl.compBetYn'(value){
    //   for(const item of this.settingList){
    //     item.compBetYn = value;
    //   }
    // },
    'totalControl.topInsureRate' (value) {
      console.log(value)
      for (const item of this.settingList) {
        item.topInsureRate = value
        item.myInsureRate = (100 - value).toFixed(2)
      }
    },
    settingList: {
      handler (settings) {
        // console.log(settings);
        let iCnt = 0
        let compCnt = 0
        for (const item of settings) {
          if (item.insureUseYn) {
            iCnt++
          }
          if (item.compBetYn) {
            compCnt++
          }
        }
        if (settings.length === iCnt) {
          this.totalControl.insureUseYn = true
        } else {
          this.totalControl.insureUseYn = false
        }
        if (settings.length === compCnt) {
          this.totalControl.compBetYn = true
        } else {
          this.totalControl.compBetYn = false
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState([
      'isPT'
    ])
  },
  methods: {
    setItem (item, key, e) {
      this.onlyNumber(e)
      item[key] = this.deleteCommas(e.target.value)
      // console.log(item[key])
    },
    amtTotalControl (e) {
      // console.log(e);
      const target = e.target.id
      const value = e.target.value
      for (const item of this.settingList) {
        item[target] = this.deleteCommas(value)
      }
    },
    async getSettingList () {
      const req = { gameType: 'sport' }
      const res = await gameSettingList(req)
      if (res.resultCode === '0') {
        const list = res.data.list
        console.log(list)
        const boolItems = ['insureUseYn', 'compBetYn']
        this.baseCreditRate = list[0].baseCreditRate
        for (const item of list) {
          item.diffCreditRate = Number(item.baseCreditRate) - Number(item.vendorCreditRate)
          item.diffCreditRate.toFixed(2)
          for (const key of boolItems) {
            if (item[key] === 'Y') {
              item[key] = true
            } else {
              item[key] = false
            }
          }
        }
        this.settingList = res.data.list
      }
    },
    setRate (item) {
      const value = item.topInsureRate
      if (!value) {
        item.topInsureRate = '0'
        return
      }
      if (value > 100) {
        item.topInsureRate = '100'
      } else {
        item.topInsureRate = Number(value).toFixed(2)
      }
    },
    setInsuredUseYn (item) {
      item.insureUseYn = !item.insureUseYn
    },
    setGameUseYn (item) {
      item.compBetYn = !item.compBetYn
    },
    setRollingRateOptionYn (item) {
      if (item.rollingRateOption === '0') {
        item.rollingRateOption = '1'
      } else {
        item.rollingRateOption = '0'
      }
    },
    setTotalInsuredUseYn (item) {
      console.log(item)
      item.insureUseYn = !item.insureUseYn
      for (const _item of this.settingList) {
        _item.insureUseYn = item.insureUseYn
      }
    },
    setTotalCompUseYn (item) {
      item.compBetYn = !item.compBetYn
      for (const _item of this.settingList) {
        _item.compBetYn = item.compBetYn
      }
    },
    async saveGameInfo () {
      const req = { gameType: 'sport' }
      const settingList = lodash.cloneDeep(this.settingList)
      for (const item of settingList) {
        item.myInsureRate = (100 - item.topInsureRate).toFixed(2)
        for (const key of this.boolItems) {
          if (item[key]) {
            item[key] = 'Y'
          } else {
            item[key] = 'N'
          }
          if (key === 'minBetAmt' || key === 'maxBetAmt') {
            item[key] = this.deleteCommas(item[key])
          }
        }
      }
      req.settingItems = settingList
      const res = await gameSettingSave(req)
      if (res.resultCode === '0') {
        alert('설정 저장 완료')
        await this.getSettingList()
      }
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    await this.getSettingList()
  }
}
</script>

<style scoped>
.h25 {height: 25px;}
.btn-search {background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);color: #252930;padding: 0 15px;border: solid 1px #808080;font-size: 12px;}
.roboto {font-size: 15px;}
.table-wrapper {padding: 0;border: 0;}
.mainTable thead {box-shadow: none;}
.mainTable th {border: solid 3px #efefef;background: #dfdfdf;color: #585963;font-size: 15px;}
.mainTable td {border: solid 3px #efefef;height: 36px;color: #585963;font-size: 15px;}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.mainTable {
  table-layout: fixed;
}
.mainTable td:last-child {
  width: 20%;
}
.noti {
  /*color: #ff7875;*/
  padding-top:5px;
  padding-bottom:5px;
}
input[type="text"].amount {
   width: 100%;
   height: 24px;
   text-align: right;
   border-color: #9c9c9c;
   font-size: 15px;
   color: #585963;
}
input[type="number"].rate {
  width: 80px;
  text-align: center;
}

.searchBar input {
  max-width: 120px;
  text-align: right;
}
.searchBar input.rate {
  max-width: 80px;
}
</style>
